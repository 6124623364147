var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box bg-center bg-no-repeat bg-size-100"},[_c('div',{staticClass:"header-user w-100 bg-no-repeat bg-center p-relative"},[(_vm.token)?[_c('div',{staticClass:"icons w-100 flex flex-end p-r-15 p-t-10"},[_c('icon-png',{attrs:{"icon":"my/message","width":25,"height":25},nativeOn:{"click":function($event){return _vm.openDownloadDialog.apply(null, arguments)}}})],1),_c('div',{staticClass:"p-l-30 p-relative m-t-5 p-r-20 user-row flex justify-between w-100"},[_c('div',{staticClass:"flex align-center w-100"},[_c('div',{key:_vm.userLogo,staticClass:"logo bg-no-repeat bg-center",style:({
                            backgroundImage: `url(${_vm.userLogo})`
                        })}),_c('div',{staticClass:"user-info overflow-hidden m-l-15 flex flex-column flex-end"},[_c('div',{},[_c('span',{staticClass:"font-16 text-white font-500 font-medium m-r-5",on:{"click":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.headerTitle.title))]),_c('span',{staticClass:"gender-logo d-inline-block bg-no-repeat bg-size-cover bg-center",style:({
                                    backgroundImage: `url(${_vm.genderLogo})`
                                })})]),_c('LongText',{staticClass:"font-12 text-white description font-regular m-t-5 font-400",attrs:{"content":_vm.headerTitle.subTitle}}),_c('div',{staticClass:"follows m-t-5 font-14 font-500 text-white"},[_c('span',{staticClass:"m-r-15"},[_vm._v("粉丝："),_c('span',{staticClass:"bg-white"},[_vm._v(_vm._s(_vm.fans_count))])]),_c('span',{staticClass:"m-l-15"},[_vm._v("关注："),_c('span',{staticClass:"bg-white"},[_vm._v(_vm._s(_vm.follow_count))])])])],1)]),_c('div',{staticClass:"personal-center text-center p-absolute font-12 font-500 text-white",on:{"click":_vm.goToDetail}},[_vm._v(" 个人中心 ")])])]:_c('div',{staticClass:"flex p-t-20 p-b-20 align-center text-white flex-column"},[_c('span',{staticClass:"font-16 font-500"},[_vm._v("登录播球")]),_c('span',{staticClass:"font-14 m-t-5 font-400"},[_vm._v("注册体验更多功能")]),_c('span',{staticClass:"login m-t-10 font-12 font-500",on:{"click":_vm.login}},[_vm._v("登录 / 注册")])])],2),_c('div',{staticClass:"content"},[_c('div',{staticClass:"new-my-service p-t-10 p-l-15 p-r-15 bg-size-100 bg-no-repeat bg-center",style:({
                    backgroundImage: `url(${_vm.bannerUlr})`
                }),on:{"click":function($event){return _vm.clickBanner(_vm.banners[0])}}}),_c('div',{staticClass:"task"},[_c('div',[_c('icon-png',{attrs:{"icon":"my/task","width":20,"height":20}}),_c('span',{staticClass:"task-center"},[_vm._v("任务中心")])],1),_c('div',{on:{"click":function($event){return _vm.openDownloadDialog()}}},[_c('span',{staticClass:"task-ticked"},[_vm._v("做任务领球票")]),_c('icon-png',{attrs:{"icon":"my/right","width":6,"height":12}})],1)]),_c('div',{staticClass:"setting-list p-b-30 p-t-20 p-l-10 p-r-10 text-white bg-center bg-no-repeat bg-size-100"},[_c('p',{staticClass:"font-14 p-l-15 font-w-800 title"},[_vm._v("我的服务")]),_c('div',{staticClass:"my-service p-b-20 p-t-15 p-l-20 p-r-20 justify-between flex align-center"},_vm._l((_vm.services),function(item){return _c('div',{key:item.id,staticClass:"item flex flex-column align-center",on:{"click":function($event){return _vm.goToPage(item)}}},[_c('icon-png',{attrs:{"icon":item.icon,"width":item.width,"height":item.height}}),_c('span',{staticClass:"font-10 font-600 text-white m-t-5"},[_vm._v(_vm._s(item.title))])],1)}),0),_c('p',{staticClass:"font-14 m-t-15 p-l-15 font-w-800 title"},[_vm._v("常用功能")]),_c('div',{staticClass:"common p-b-20 p-t-15 p-l-20 p-r-20 justify-between flex align-center"},[_vm._l((_vm.commons),function(item){return _c('div',{key:item.id,staticClass:"item flex flex-column align-center",on:{"click":function($event){return _vm.goToPage(item)}}},[_c('icon-png',{attrs:{"icon":item.icon,"width":item.width,"height":item.height}}),_c('span',{staticClass:"font-10 font-600 text-white m-t-5"},[_vm._v(_vm._s(item.title))])],1)}),_vm._m(0),_vm._m(1)],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item visibility-hidden"},[_c('span',[_vm._v("占位用")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item visibility-hidden"},[_c('span',[_vm._v("占位用")])])
}]

export { render, staticRenderFns }